/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Nairo Personal Portfolio
Version:    1.0
Primary use:    pxdraft
-------------------------------------------------------------------*/
/*
) Base
) Buttons
) Header
) Footer
) Titles
) Title
) Home Banner
) About Us
) Resume
) Work
) Blog
) Contact
*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  overflow-x: hidden;
}

body {
  background: #10101a;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Rubik", monospac;
  line-height: 1.7;
  font-size: 15px;
  font-weight: 300;
}
body.theme-light {
  background: #fff;
  color: #555;
  font-weight: 400;
}

.font-alt {
  font-family: "Rubik", serif;
}

img {
  max-width: 100%;
}

* {
  outline: none !important;
}

a {
  color: #90238c;
}
a:hover {
  color: #d77c00;
  text-decoration: none;
}

mark {
  background-image: linear-gradient(#90238c, #90238c);
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}

/* ----------------------
*	Loading
---------------------------*/
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #90238c;
  z-index: 99999;
}

.load-circle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}
.load-circle span {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.load-circle span:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
  text-align: center;
}
.owl-dots .owl-dot {
  display: inline-block;
  vertical-align: top;
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #fff;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  border-radius: 50%;
  margin: 0 5px;
}
.theme-light .owl-dots .owl-dot {
  border-color: #0b0b13;
}
.owl-dots .owl-dot.active {
  background: #fff;
}
.theme-light .owl-dots .owl-dot.active {
  background: #0b0b13;
}

.owl-carousel .owl-item img {
  width: auto;
  max-width: 100%;
}

.px-btn {
  padding: 0 25px;
  line-height: 42px;
  position: relative;
  display: inline-block;
  background: none;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
}
.px-btn.px-btn-white {
  background: #fff;
  border-color: #fff;
  color: #0b0b13;
}
.theme-light .px-btn.px-btn-white {
  color: #fff;
  background: #0b0b13;
  border-color: #0b0b13;
}
.px-btn.px-btn-white:hover {
  background: transparent;
  color: #fff;
}
.theme-light .px-btn.px-btn-white:hover {
  color: #0b0b13;
}

/* Bg color
----------------------------*/
.dark-bg {
  background: #0e0e17;
}
.theme-light .dark-bg {
  background: #f7f7ff;
}

@media (min-width: 768px) {
  .main-left {
    padding-left: 80px;
  }
}

.header-left {
  width: 80px;
  position: fixed;
  bottom: 0;
  top: 0;
  background: #0c0c14;
  border-right: 1px solid rgba(11, 11, 19, 0.1);
}
.theme-light .header-left {
  background: #fff;
}
.header-left .scroll-bar {
  height: 100%;
}
.header-left .hl-top a {
  background: #90238c;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  font-size: 40px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.header-left .nav-menu {
  padding-top: 45px;
  padding-bottom: 45px;
}
.header-left .nav-menu li {
  width: 100%;
}
.header-left .nav-menu li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.theme-light .header-left .nav-menu li + li {
  border-top: 1px solid rgba(11, 11, 19, 0.1);
}
.header-left .nav-menu li .nav-link {
  margin: 0;
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  text-align: center;
}
.theme-light .header-left .nav-menu li .nav-link {
  color: #0b0b13;
}
.header-left .nav-menu li .nav-link i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 25px;
}
@media (min-width: 768px) {
  .header-left .nav-menu li .nav-link:hover {
    color: #90238c;
  }
}
.header-left .nav-menu li.active .nav-link {
  color: #90238c;
  font-weight: 600;
}
.header-left .social-icons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80px;
  display: none;
}
.header-left .social-icons a {
  color: #fff;
  font-size: 14px;
  margin-right: 17px;
}
.header-left .social-icons a:hover {
  color: #90238c;
}

.tooltip {
  font-size: 12px;
}

.mob-header {
  padding: 15px;
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}
.mob-header > .d-flex {
  align-items: center;
}
.mob-header .navbar-brand {
  padding: 0;
}
.mob-header .logo-text {
  font-weight: 600;
  color: #fff;
  font-size: 25px;
  line-height: 1;
}
.theme-light .mob-header .logo-text {
  color: #0b0b13;
}
.fixed-header .mob-header {
  background: #0b0b13;
}
.theme-light.fixed-header .mob-header {
  background: #fff;
}

.toggler-menu {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0;
  border-radius: 0;
  padding: 0;
  margin-left: 15px;
  background: #90238c;
  border: none;
  margin-left: auto;
}
.toggler-menu span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 2px;
  margin: auto;
  box-shadow: 0px -8px 0 0px currentColor, 0px 8px 0 0px currentColor;
  background: #fff;
  color: #fff;
}

@media (max-width: 767px) {
  .mob-header {
    display: block;
  }

  .header-left {
    -moz-transition: ease all 0.35s;
    -o-transition: ease all 0.35s;
    -webkit-transition: ease all 0.35s;
    transition: ease all 0.35s;
    left: -200px;
    z-index: 222;
  }
  .header-left.menu-open {
    left: 0;
  }
}
.footer {
  padding: 12px 0;
  background: #0b0b13;
}
.theme-light .footer {
  background: #fff;
  border-top: 1px solid rgba(11, 11, 19, 0.1);
}
.footer .nav a {
  color: #fff;
  font-size: 14px;
  margin-right: 17px;
}
.theme-light .footer .nav a {
  color: #0b0b13;
}
.footer .nav a:hover {
  color: #90238c;
}
.footer p {
  color: #fff;
  font-size: 14px;
  margin: 0;
}
.theme-light .footer p {
  color: #0b0b13;
}

.blog-grid .blog-img {
  overflow: hidden;
}
.blog-grid .blog-img img {
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog-grid .blog-info {
  padding-top: 15px;
}
.blog-grid .blog-info .meta {
  font-size: 12px;
}
.blog-grid .blog-info h6 {
  font-size: 24px;
  font-weight: 500;
  padding-top: 10px;
}
@media (max-width: 767px) {
  .blog-grid .blog-info h6 {
    font-size: 20px;
  }
}
.blog-grid .blog-info a {
  color: #fff;
  background-image: linear-gradient(130deg, #fff, #fff);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}
.theme-light .blog-grid .blog-info a {
  color: #0b0b13;
  background-image: linear-gradient(130deg, #0b0b13, #0b0b13);
}
.blog-grid:hover .blog-info a {
  background-size: 100% 2px;
}
.blog-grid:hover .blog-img img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.read-more-blog {
  padding-top: 30px;
}

.blog-pagination {
  padding-top: 30px;
}

.pagination .page-item .page-link {
  border: none;
  box-shadow: none;
  color: #fff;
  margin: 0 5px;
  border-radius: 50px;
  padding: 0;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  background: rgba(255, 255, 255, 0.1);
}
.theme-light .pagination .page-item .page-link {
  background: rgba(11, 11, 19, 0.2);
  color: #0b0b13;
  font-weight: 500;
}
.pagination .page-item .page-link:hover {
  background: #90238c;
  border-color: #90238c;
  color: #fff;
}
.pagination .page-item.disabled .page-link {
  opacity: 0.5;
}
.pagination .page-item.active .page-link {
  background: #90238c;
  border-color: #90238c;
  color: #fff;
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #fff;
  background: #0b0b13;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-weight: 400;
}
.theme-light .tag-cloud a {
  color: #0b0b13;
  background: #f7f7ff;
}
.tag-cloud a:hover {
  background: #90238c;
  color: #fff;
}

.blog-listing {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .blog-listing {
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .blog-listing {
    padding-bottom: 50px;
  }
}

.single-blog {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .single-blog {
    padding-top: 100px;
    padding-bottom: 80px;
  }
}

.article {
  margin: 15px 0 30px;
}
.article .article-title {
  padding: 15px 0 20px;
}
.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.article .article-title h6 a {
  text-transform: uppercase;
  color: #90238c;
  border-bottom: 1px solid #90238c;
}
.article .article-title h2 {
  color: #fff;
  font-weight: 600;
}
.theme-light .article .article-title h2 {
  color: #0b0b13;
}
@media (max-width: 991px) {
  .article .article-title h2 {
    font-size: 28px;
  }
}
.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
}
.theme-light .article .article-title .media {
  border-bottom: 1px dashed rgba(11, 11, 19, 0.1);
}
.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}
.article .article-title .media .media-body {
  padding-left: 8px;
}
.article .article-title .media .media-body label {
  font-weight: 600;
  color: #90238c;
  margin: 0;
}
.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}
.article .article-content h1, .article .article-content h2, .article .article-content h3, .article .article-content h4, .article .article-content h5, .article .article-content h6 {
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 30px;
}
.theme-light .article .article-content h1,
.theme-light .article .article-content h2,
.theme-light .article .article-content h3,
.theme-light .article .article-content h4,
.theme-light .article .article-content h5,
.theme-light .article .article-content h6 {
  color: #0b0b13;
}
.article .article-content blockquote {
  max-width: 600px;
  padding: 15px 0 30px 0;
  margin: 0;
}
.article .article-content blockquote p {
  font-size: 20px;
  font-weight: 500;
  color: #90238c;
  margin: 0;
}
.article .article-content blockquote .blockquote-footer {
  color: #fff;
  font-size: 16px;
}
.theme-light .article .article-content blockquote .blockquote-footer {
  color: #0b0b13;
}
.article .article-content blockquote .blockquote-footer cite {
  font-weight: 600;
}
.article .tag-cloud {
  padding-top: 10px;
}

.article-comment {
  border-radius: 5px;
  overflow: hidden;
  background: #0b0b13;
  padding: 30px;
}
.theme-light .article-comment {
  background: #f7f7ff;
}
.article-comment h4 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 22px;
}
.theme-light .article-comment h4 {
  color: #0b0b13;
}

/*---------------------------
 Portfolio Start
------------------------------*/
.portfolio-content.grid-col-2 .grid-item {
  width: 50%;
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-2 .grid-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .portfolio-content.grid-col-3 .grid-item {
    width: 33.3333%;
  }
}
@media (max-width: 991px) {
  .portfolio-content.grid-col-3 .grid-item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-3 .grid-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 33.3333%;
  }
}
@media (min-width: 1201px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 25%;
  }
}
@media (max-width: 991px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-4 .grid-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 33.3333%;
  }
}
@media (min-width: 1201px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 20%;
  }
}
@media (max-width: 991px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .portfolio-content.grid-col-5 .grid-item {
    width: 100%;
  }
}
.portfolio-content.grid-gutter-md {
  margin-left: -4px;
  margin-right: -4px;
}
.portfolio-content.grid-gutter-md .grid-item {
  padding: 8px;
}
.portfolio-content.grid-gutter-lg {
  margin-left: -12px;
  margin-right: -12px;
}
.portfolio-content.grid-gutter-lg .grid-item {
  padding: 12px;
}
.portfolio-content .grid-item {
  float: left;
}

/*Portfolio Filter*/
.portfolio-filter-01 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.portfolio-filter-01 .filter li {
  cursor: pointer;
  margin: 0 15px;
  color: #fff;
  position: relative;
  padding: 5px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
}
.theme-light .portfolio-filter-01 .filter li {
  color: #0b0b13;
}
@media (max-width: 767px) {
  .portfolio-filter-01 .filter li {
    margin: 0 8px;
    font-size: 15px;
  }
}
.portfolio-filter-01 .filter li:after {
  content: "";
  width: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background: #90238c;
  position: absolute;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
}
.portfolio-filter-01 .filter li:last-child {
  margin-right: 0;
}
.portfolio-filter-01 .filter li:first-child {
  margin-left: 0;
}
.portfolio-filter-01 .filter li.active:after {
  width: 100%;
}

/*Portfolio Style 2*/
.portfolio-box-01 {
  background: #fff;
  position: relative;
}
.portfolio-box-01 .portfolio-img {
  position: relative;
}
.portfolio-box-01 .portfolio-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}
.portfolio-box-01 .portfolio-icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  background: #0b0b13;
  font-size: 24px;
  text-align: center;
}
.portfolio-box-01 .portfolio-icon a span {
  line-height: inherit;
}
.portfolio-box-01 .portfolio-info {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 20px;
  z-index: 1;
  background: #0b0b13;
  right: 20px;
  text-align: center;
  opacity: 0;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}
.portfolio-box-01 .portfolio-info h5 {
  margin: 0 0 4px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
}
.portfolio-box-01 .portfolio-info h5 a {
  color: #fff;
}
.portfolio-box-01 .portfolio-info span {
  color: rgba(255, 255, 255, 0.6);
}
.portfolio-box-01:hover .portfolio-info {
  opacity: 1;
}
.portfolio-box-01:hover .portfolio-icon {
  opacity: 1;
}

/* Section
---------------------*/
.section {
  padding: 100px 0;
  position: relative;
}
@media (max-width: 991px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.m-15px-tb {
  margin-top: 15px;
  padding-bottom: 15px;
}

/* Title
-----------------------------------*/
.title {
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .title {
    margin-bottom: 25px;
  }
}
.title h3 {
  margin: 0;
  color: #fff;
  position: relative;
  z-index: 1;
  font-weight: 600;
  font-size: 30px;
  display: inline-block;
  vertical-align: top;
}
.theme-light .title h3 {
  color: #0b0b13;
}
.title h3:after {
  content: "";
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #90238c;
  position: absolute;
  top: -8px;
  left: -15px;
  z-index: -1;
}
.theme-light .title h3:after {
  background: rgba(255, 147, 1, 0.5);
}
@media (max-width: 991px) {
  .title h3:after {
    top: -4px;
    left: 0;
  }
}

.separated {
  /* background-image: url(../img/border.png); */
  padding-top: 80px;
  padding-bottom: 80px;
  background-repeat: repeat-x;
  background-size: auto 6px;
  background-position: center;
}
.theme-light .separated {
  /* background-image: url(../img/border-dark.png); */
}
@media (max-width: 991px) {
  .separated {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .separated {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

/* Home Banner
-----------------------------------*/
.home-banner {
  position: relative;
  overflow: hidden;
}
.home-banner:after {
  content: "";
  position: absolute;
  right: -20vh;
  bottom: -20vh;
  width: 120vh;
  height: 120vh;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.theme-light .home-banner:after {
  background: #f7f7ff;
}
.home-banner .container {
  position: relative;
  z-index: 1;
}
.home-banner .hb-top-fixed {
  position: absolute;
  padding: 30px 50px 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .home-banner .hb-top-fixed {
    display: none !important;
  }
}
.home-banner .hb-top-fixed .hb-info label {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.theme-light .home-banner .hb-top-fixed .hb-info label {
  color: #0b0b13;
}
.home-banner .hb-top-fixed .hb-info label + label {
  margin-left: 40px;
}
.home-banner .hb-top-fixed .hb-lang {
  margin-left: auto;
}
.home-banner .hb-top-fixed .hb-lang li + li {
  margin-left: 10px;
}
.home-banner .hb-top-fixed .hb-lang li a {
  color: #fff;
  font-size: 14px;
  padding: 5px 8px;
}
.theme-light .home-banner .hb-top-fixed .hb-lang li a {
  color: #0b0b13;
  font-weight: 500;
}
.home-banner .hb-top-fixed .hb-lang li:hover, .home-banner .hb-top-fixed .hb-lang li.active {
  background: #90238c;
}
.theme-light .home-banner .hb-top-fixed .hb-lang li:hover a, .theme-light .home-banner .hb-top-fixed .hb-lang li.active a {
  color: #fff;
}
.home-banner .hb-me {
  position: absolute;
  right: 0;
  width: 50vw;
  height: 100%;
  background-size: cover;
  top: 0;
  background-repeat: no-repeat;
  background-position: top left;
}
@media (max-width: 991px) {
  .home-banner .hb-me {
    opacity: 0.4;
    width: 80vw;
  }
}
.home-banner .full-screen {
  min-height: 100vh;
}
.home-banner .type-box {
  padding-top: 90px;
  padding-bottom: 50px;
}
.home-banner .type-box h6 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px;
}
.theme-light .home-banner .type-box h6 {
  color: #0b0b13;
}
@media (max-width: 767px) {
  .home-banner .type-box h6 {
    font-size: 18px;
    margin: 0 0 10px;
  }
}
.home-banner .type-box h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  margin: 0 0 20px;
  color: #fff;
}
.theme-light .home-banner .type-box h1 {
  color: #0b0b13;
}
@media (max-width: 1200px) {
  .home-banner .type-box h1 {
    font-size: 70px;
  }
}
@media (max-width: 767px) {
  .home-banner .type-box h1 {
    font-size: 50px;
  }
}
.home-banner .type-box .lead {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.theme-light .home-banner .type-box .lead {
  color: #0b0b13;
}
@media (max-width: 767px) {
  .home-banner .type-box .lead {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.home-banner .type-box .desc {
  max-width: 450px;
}
.home-banner .type-box .btn-bar {
  padding-top: 10px;
}
.home-banner .type-box .btn-bar a {
  color: #fff;
  font-size: 25px;
  line-height: 1;
  font-weight: 400;
  position: relative;
  text-decoration: none;
  padding-left: 0;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}
.theme-light .home-banner .type-box .btn-bar a {
  color: #0b0b13;
}
@media (max-width: 767px) {
  .home-banner .type-box .btn-bar a {
    font-size: 20px;
  }
}
.home-banner .type-box .btn-bar a:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 0;
  height: 2px;
  background: currentColor;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
}
.home-banner .type-box .btn-bar a:hover {
  padding-left: 45px;
}
.home-banner .type-box .btn-bar a:hover:after {
  width: 40px;
}

/* About
-----------------------------------*/
@media (max-width: 991px) {
  .about-me {
    margin-bottom: 40px;
  }
}
.about-me .img-in {
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #fff;
}
.theme-light .about-me .img-in {
  border: 5px solid #90238c;
}
.about-me .img {
  position: relative;
}
.about-me .info {
  text-align: center;
  padding-top: 40px;
  color: #fff;
}
.theme-light .about-me .info {
  color: #0b0b13;
}
.about-me .info h3 {
  font-weight: 500;
  font-size: 30px;
}
.about-me .info p {
  margin: 0;
  font-size: 16px;
}
.about-me .social-icons {
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
}
.about-me .social-icons a {
  width: 35px;
  height: 35px;
  line-height: 31px;
  background: #fff;
  border: 2px solid #fff;
  color: #0b0b13;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
  -moz-transition: ease all 0.35s;
  -o-transition: ease all 0.35s;
  -webkit-transition: ease all 0.35s;
  transition: ease all 0.35s;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.theme-light .about-me .social-icons a {
  background: #0b0b13;
  color: #fff;
}
.about-me .social-icons a i {
  line-height: inherit;
}
.about-me .social-icons a:nth-child(1) {
  top: -25px;
}
.about-me .social-icons a:nth-child(2) {
  top: -8px;
}
.about-me .social-icons a:nth-child(4) {
  top: -8px;
}
.about-me .social-icons a:nth-child(5) {
  top: -25px;
}
.about-me .social-icons a:hover {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  background: #90238c;
  color: #fff;
}

.about-info .title {
  margin-bottom: 30px;
}
.about-info .info-list {
  padding-top: 15px;
}
.about-info .info-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.about-info .info-list li {
  padding: 4px 0;
}
.about-info .info-list label {
  color: #fff;
  margin: 0;
  font-weight: 500;
  padding-right: 10px;
}
.theme-light .about-info .info-list label {
  color: #0b0b13;
}

/* Feature Box
-----------------------------------*/
.feature-box-01 {
  background: #0b0b13;
  padding: 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.theme-light .feature-box-01 {
  background: #fff;
}
.feature-box-01:before {
  content: "";
  position: absolute;
  top: -220px;
  left: -220px;
  width: 200px;
  height: 200px;
  border-radius: 0;
  z-index: -1;
  background: #0b0b13;
  -moz-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
  -o-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
  -webkit-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
}
.feature-box-01:after {
  content: "";
  position: absolute;
  top: -72px;
  left: -40px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  z-index: -2;
  background: #90238c;
  -moz-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.15s;
  -o-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.15s;
  -webkit-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.15s;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.15s;
}
.feature-box-01 .icon {
  line-height: 60px;
  font-size: 40px;
  color: #fff;
}
.feature-box-01 .feature-content {
  padding-top: 25px;
}
.feature-box-01 h5 {
  margin-bottom: 15px;
  color: #fff;
}
.theme-light .feature-box-01 h5 {
  color: #0b0b13;
}
.feature-box-01 p {
  margin: 0;
}
.theme-light .feature-box-01:hover {
  color: #fff;
}
.theme-light .feature-box-01:hover h5 {
  color: #fff;
}
.feature-box-01:hover:before {
  top: -72px;
  left: -40px;
  border-radius: 50%;
}
.feature-box-01:hover:after {
  content: "";
  width: 120%;
  left: -10%;
  top: -10%;
  height: 120%;
  border-radius: 0;
  -moz-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
  -o-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
  -webkit-transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
  transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
}

.feature-box-02 {
  background: #0b0b13;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
}
.theme-light .feature-box-02 {
  background: #fff;
}
.feature-box-02 .icon {
  width: 70px;
}
.feature-box-02 .media-body {
  padding-left: 15px;
}
.feature-box-02 h6 {
  color: #fff;
  margin: 0 0 5px;
  font-size: 18px;
}
.theme-light .feature-box-02 h6 {
  color: #0b0b13;
}
.feature-box-02 p {
  margin: 0;
  font-size: 14px;
}

/* testimonial
-----------------------------------*/
.testimonial-01 {
  background: #0b0b13;
  padding: 35px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 25px;
}
.theme-light .testimonial-01 {
  background: #fff;
}
.testimonial-01 .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .testimonial-01 .avatar {
    width: 50px;
    height: 50px;
  }
}
.testimonial-01 .media-body {
  padding-left: 25px;
}
@media (max-width: 767px) {
  .testimonial-01 .media-body {
    padding-left: 15px;
  }
}
.testimonial-01 h6 {
  color: #fff;
  margin: 0 0 5px;
}
.theme-light .testimonial-01 h6 {
  color: #0b0b13;
}
.testimonial-01 span {
  font-size: 13px;
}

/* Experience
-----------------------------------*/
.resume-box {
  padding-top: 15px;
}

.resume-row {
  background: #0b0b13;
  padding: 35px;
  border-radius: 15px;
}
.theme-light .resume-row {
  background: #f7f7ff;
}
.resume-row + .resume-row {
  margin-top: 30px;
}
.resume-row h6 {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}
.theme-light .resume-row h6 {
  color: #0b0b13;
}
.resume-row .rb-left {
  min-height: 100%;
}
@media (min-width: 768px) {
  .resume-row .rb-left {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
  }
  .theme-light .resume-row .rb-left {
    border-right: 1px solid rgba(11, 11, 19, 0.05);
  }
}
@media (max-width: 767px) {
  .resume-row .rb-left {
    margin-bottom: 25px;
  }
}
.resume-row .rb-left h6 {
  margin-bottom: 5px;
}
.resume-row .rb-left label {
  font-size: 14px;
  font-style: italic;
}
.resume-row .rb-left p {
  margin-top: 5px;
  margin-bottom: 8px;
  line-height: normal;
}
.resume-row .rb-left .rb-time {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  color: #fff;
  background: #90238c;
  font-size: 12px;
  font-weight: 500;
}
.resume-row .rb-right p {
  margin-bottom: 0;
}

.skill-lt {
  position: relative;
  padding-left: 80px;
}
.skill-lt span {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  left: 0;
  top: 8px;
  position: absolute;
}
.theme-light .skill-lt span {
  color: #0b0b13;
}
.skill-lt:not(:first-child) {
  margin-top: 45px;
}
.skill-lt h6 {
  font-size: 15px;
  margin: 0 0 10px;
  font-weight: 400;
  color: #fff;
}
.theme-light .skill-lt h6 {
  color: #0b0b13;
}
.skill-lt .skill-bar {
  position: relative;
  background: rgba(255, 255, 255, 0.2);
}
.theme-light .skill-lt .skill-bar {
  background: rgba(11, 11, 19, 0.1);
}
.skill-lt .skill-bar .skill-bar-in {
  position: relative;
  -moz-transition: ease all 0.55s;
  -o-transition: ease all 0.55s;
  -webkit-transition: ease all 0.55s;
  transition: ease all 0.55s;
  height: 10px;
  background: #90238c;
}

.aducation-box {
  margin: 0;
  padding: 35px;
  border-radius: 10px;
  background: #0b0b13;
  list-style: none;
}
.theme-light .aducation-box {
  background: #f7f7ff;
}
@media (max-width: 991px) {
  .aducation-box {
    margin-bottom: 10px;
  }
}
.aducation-box p {
  margin: 0;
}
.aducation-box h6 {
  color: #fff;
  margin: 5px 0;
}
.theme-light .aducation-box h6 {
  color: #0b0b13;
}
.aducation-box li + li {
  margin-top: 25px;
}

/* Contact Us
-----------------------------------*/
@media (max-width: 991px) {
  .contact-info {
    margin-bottom: 20px;
  }
}
.contact-info h4 {
  font-size: 34px;
  color: #fff;
  font-weight: 500;
}
.theme-light .contact-info h4 {
  color: #0b0b13;
}
.contact-info p {
  font-size: 16px;
}
.contact-info ul {
  margin: 0;
  padding: 10px 0 0;
  list-style: none;
}
.contact-info li {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 1.3;
  position: relative;
}
.theme-light .contact-info li {
  color: #0b0b13;
}
.contact-info li i {
  width: 30px;
  text-align: center;
  color: #90238c;
  font-size: 22px;
}
.contact-info li span {
  padding-left: 10px;
}
.contact-info li + li {
  padding-top: 20px;
}

@media (max-width: 991px) {
  .contact-form {
    padding-bottom: 20px;
  }
}
.contact-form h4 {
  font-weight: 500;
  color: #fff;
  font-size: 22px;
  margin-bottom: 20px;
}
.theme-light .contact-form h4 {
  color: #0b0b13;
}
.contact-form .form-control {
  color: #fff;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  height: calc(2em + .75rem + 2px);
}
.contact-form .form-control.invalid {
  border-color: #dc3545 !important;
}
.theme-light .contact-form .form-control {
  color: #0b0b13;
  border: 1px solid rgba(11, 11, 19, 0.3);
  background: #fff;
}
.contact-form textarea.form-control {
  height: auto;
}

.google-map {
  margin-top: 50px;
}
.theme-light .google-map {
  border: 5px solid #fff;
}
@media (max-width: 767px) {
  .google-map {
    margin-top: 15px;
  }
}
.google-map .embed-responsive-21by9 {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}
.google-map .embed-responsive-21by9:before {
  padding-top: 30%;
}
@media (max-width: 767px) {
  .google-map .embed-responsive-21by9:before {
    padding-top: 55%;
  }
}

/*# sourceMappingURL=style.css.map */
